import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Integrations",
  "sort": 3,
  "title": "PingFederate",
  "subtitle": "This tutorial demonstrates how to integrate Criipto Verify with PingFederate"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Highlight = makeShortcode("Highlight");
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This tutorial demonstrates how to integrate Criipto Verify with PingFederate. The following steps are required to complete your first login:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#prepare-criipto-verify-application"
        }}>{`Prepare an application in Criipto Verify that represents your PingFederate tenant`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#create-pingfederate-authentication-source"
        }}>{`Create an authentication source in your PingFederate tenant which connects to the Criipto Verify application`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#complete-the-application-configuration-in-criipto-verify"
        }}>{`Complete the application configuration in Criipto Verify`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#integrate-your-own-application-with-pingfederate"
        }}>{`Integrate your own application with PingFederate`}</a></li>
    </ol>
    <p>{`In the following you will be configuring first Criipto Verify, then PingFederate, and then back to finalizing the configuration in Criipto Verify.`}</p>
    <p>{`The final step in this excercise is needed because of a catch-22 between the requirements of the configuration steps on the two platforms, respectively:`}</p>
    <ul>
      <li parentName="ul">{`Creating an application in Criipto Verify requires you to specify a callback URL to PingFederate before you can save the application configuration and get your hands on the generated client secret.`}</li>
      <li parentName="ul">{`Getting the callback URL to PingFederate requires that you create an authentication source, which in turn requires the client secret from Criipto Verify, but you don't have the secret yet because you need the callback URL `}<inlineCode parentName="li">{`[deadlock detected]`}</inlineCode></li>
    </ul>
    <p>{`which `}<em parentName="p">{`is`}</em>{` a bit of a chicken-and-egg-problem, unfortunately. We suggest that you break the deadlock by configuring a temporary (bogus) callback URL in the first step in Criipto Verify, and then replace it with the actual value available after the authentication source is created.`}</p>
    <p>{`As the setup requires some switching back-and-forth between Criipto and PingFederate's respective management dashboards, we recommend that you have them open simultaneously to make the process fairly smooth.`}</p>
    <p>{`Once configured you may test that everything works from PingFederate's `}<inlineCode parentName="p">{`OAuth Playground`}</inlineCode>{`.`}</p>
    <h2>{`Prepare Criipto Verify application`}</h2>
    <p>{`First, you must register your PingFederate tenant as an application in Criipto Verify.`}</p>
    <p>{`Once you register your PingFederate tenant, you will also need some of the information for configuring PingFederate to communicate with Criipto Verify. You get these details from the settings of the application in the dashboard.`}</p>
    <p>{`Specifically you need the following information to integrate with PingFederate:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Client ID`}</em>{` to identify your PingFederate tenant to Criipto Verify. In the case below we chose `}<inlineCode parentName="li">{`urn:criipto:verify`}</inlineCode></li>
      <li parentName="ul"><em parentName="li">{`Domain`}</em>{` on which you will be communicating with Criipto Verify. Could be for example `}<inlineCode parentName="li">{`samples.criipto.id`}</inlineCode></li>
      <li parentName="ul"><em parentName="li">{`Client secret`}</em>{` which PingFederate needs to fetch actual user information from Criipto Verify during login.
The secret is generated when you save the new application.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "172.91666666666669%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAjCAYAAACU9ioYAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACz0lEQVRIx61W2XKbQBDc//+SvORTEieyLEtcCwhJ3FpuEEidmkF2HMcHKKGqawGh2bm2p0VoGPA1DYFhIDJNRqDrDHqf2jay7RaZ6yLbfgzluhDeagXj+zd4Dw/YLpfYrVaQP3/AXS5hLxaIDAM5GXOcj+G6UI4DEUuJxHXRhCHqIEAVBKiv97RWvo/icED5GXwfuedBdEWBpm5QlSX6vgcuF5yHAefzmXGZCLrq9AgRWxb2mobIssY8WibvNBeF53GuRZkkyNIUhVK8NmWJoW3Rz8TQdSjDEKLwfWRBCEU5CEO0xyOaG5Hv9xCJ4yC0HQRSInIcVFE0H1TEKEJBBtssQ9e1aKoKXdOMBen72aDC1GkK4WsbxKaJVEoo257Wc+9g7EPHxp5Oh5RIt1t2/5awKWTOYUf9Nww4nU5ouxYn6sUbr+aoIE5Vhf91NUpBdErh3HUYqCCEtuWVNpqDvq45dHFYr+EuFkwKRA7OYsGrurEwIrFtpqnDZoPd4yOvRF10jPKZIMYRdZIwo0SGzsbyrYdytxv5z3UnG3o2SOcv3+0Q6qNB6snXH/2TwcSynn+83cP9HoGmYf+OwZH+ZxoMjaeQLZTEb68+nh8yG9RAQ4tIl3KpbGdS+O/mkNqHsdmM+ZRyvsEqjn/ncL3G0XbGkInaZ4bMY5S8o4fUluyRuo7Ev4oy1UM6ZubdHc9hmtH2/T17mt9aFOIxagkqCh05GuxcGNN8M4dvbfBHyL60cfQ87r8n5n55/9YfP/Tw65ev0FdrpNLiyrLO0bXrquN4pfZsKjmQJ0RhVOUn0DO1zst3I525n8+USEr4pgmfVBjBNOFtNlCexzqHNMtUZKxtygJt06DvupHHL5fJeuY1yjiGYD1IntFJIV1I8o0am/pzjra59rMgq+qqbxpSYkWBnjyei7ZFEQQQTRLztGqVwinP0eU5SE3MRp6DdNIv1tNEoOMxOYEAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Prepare App",
          "title": "Prepare App",
          "src": "/static/8f557f203129daabafe70092ac6916b0/0a47e/pingfederate-prepare-application.png",
          "srcSet": ["/static/8f557f203129daabafe70092ac6916b0/8514f/pingfederate-prepare-application.png 192w", "/static/8f557f203129daabafe70092ac6916b0/804b2/pingfederate-prepare-application.png 384w", "/static/8f557f203129daabafe70092ac6916b0/0a47e/pingfederate-prepare-application.png 600w"],
          "sizes": "(max-width: 600px) 100vw, 600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`Click the `}<strong parentName="p">{`Save`}</strong>{` button - and a popup will appear after a little while with your client secret.
Make a copy of it (preferably by pasting it directly into your PingFederate authentication source configuration).`}</p>
    <p>{`The application details pane will be hidden automatically - you can expand it again by clicking anywhere on the line item with the name of your new application (in this article, that would be `}<inlineCode parentName="p">{`PingFederate`}</inlineCode>{`).`}</p>
    <h2>{`Create PingFederate authentication source`}</h2>
    <p>{`On the dashboard of your PingFederate tenant, go to the `}<inlineCode parentName="p">{`Authentication`}</inlineCode>{` tab and click on the `}<inlineCode parentName="p">{`IdP Connections`}</inlineCode>{` tile`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "60.416666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7DAAAOwwHHb6hkAAACBElEQVQoz32SW2/TQBCF/X/LA/QBqZWQCrRNc21JUgRF0ABS4d9QUVWCJI4Tx/d7HMdO7F0ftOs4TShipSOvZ6Rv58yMcHTSQLXVxcvTJtj96fMXeLJ/uNHeswPs7R/sxLZzh0cVnNbf4HX1HMe1Cwit7gdc9W7Qed9D+901juttvDq7wEm9jbNmB7XzS9Ral6g2u6g0OjxWqtJo4+1VD9dfv+Pj5xt8+vINghvG0OwAhjuDHSwQrSjCJQFFcaJFjDhJQGkZ+f8RDDfkMN0JYHohvHkCJ4wRrzIQQjgsWS6RZQR5niPLMn7PCOGPEEJBKOU5JqEvTSHrNkRZh2w48KMl3DBBsgaWlYkjCT9u7/idxRmYA9f5vqTg90iBMNFsTA0Hqu2D2d8GUvJQle04UDUdaZbxWPlYnoN/DcuBbjoQVMuHYnpQTBd2EO0CKUEcJxiKEkRpjJE0wWA4QhQtNpbZMUwL/aGI/kCEMBir3DIDBosVlzt/sDwL55jICkZjGdJYhqLq8DwfaZpyWDiPcPvzHnf3vwqgZvvQnACsUjYUy59z60laANkpLNKdnma8HeADc1yPy/MDCJJiYqJZGKsWRlMDoqzBmS2QpGQNyB+tBt308HFOYD1jNmdxutG2ZbYihBbVcVHKqyumnPN/uiWB7d3fKoHME+vVv7SZ8Hr/yj38A730a4MgS+tkAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IdP Connections",
          "title": "IdP Connections",
          "src": "/static/66733e767d10991da315bd930c8043d3/0a47e/pingfederate-idpconnections.png",
          "srcSet": ["/static/66733e767d10991da315bd930c8043d3/8514f/pingfederate-idpconnections.png 192w", "/static/66733e767d10991da315bd930c8043d3/804b2/pingfederate-idpconnections.png 384w", "/static/66733e767d10991da315bd930c8043d3/0a47e/pingfederate-idpconnections.png 600w"],
          "sizes": "(max-width: 600px) 100vw, 600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`Click the `}<strong parentName="p">{`Create Connection`}</strong>{` button and choose `}<inlineCode parentName="p">{`BROWSER SSO PROFILES`}</inlineCode>{` in `}<inlineCode parentName="p">{`Connection Type`}</inlineCode>{` and then `}<inlineCode parentName="p">{`OpenID Connect`}</inlineCode>{` in the `}<inlineCode parentName="p">{`PROTOCOL`}</inlineCode>{` dropdown`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "46.35416666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABIElEQVQoz6WOzUrEMBRG+/6P48JxVIRBcOVGYRzRhRt1+pe2uUnTpNP2SCriDCKKfnD4SHLvIUmhLdttihGNfrykWi+pN2eo9ZLq7hT7cI7ZHGM2iwPs/ckeS8z6CJ9tSLJaqCqFFuHi6obF6prnbU6alxSqoRFLrc3cqhFqbRlGGKdPJmDkvZOsrEizjEZr8kLx8pqS5QVKVWRZQR7FRUlexFYUpTo4l6VCxGCMJYSepJYWEY1zDms7RCxiWsQ40rKmEouxHa3t5vfvMMbho1CcJwRP3wec87OsdR3e9/PA3L8g/m4cBhJp43I3C+NllDnXMQwDwzD+inEccWGH8bso9ATfEfrAf3Kbtqye9KFwmqY/85Evwpj9gZ+yvxN5A8oPteaP1uCNAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Create Connection",
          "title": "Create Connection",
          "src": "/static/50936874632f08cd1596da096a91ae31/e5715/pingfederate-connection-type.png",
          "srcSet": ["/static/50936874632f08cd1596da096a91ae31/8514f/pingfederate-connection-type.png 192w", "/static/50936874632f08cd1596da096a91ae31/804b2/pingfederate-connection-type.png 384w", "/static/50936874632f08cd1596da096a91ae31/e5715/pingfederate-connection-type.png 768w", "/static/50936874632f08cd1596da096a91ae31/5a190/pingfederate-connection-type.png 800w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`Click the `}<strong parentName="p">{`Next`}</strong>{` button, choose `}<inlineCode parentName="p">{`BROWSER SSO`}</inlineCode>{` in `}<inlineCode parentName="p">{`Connection Options`}</inlineCode></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "47.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABXUlEQVQoz63Rz0/bMBjG8fzrbIjCUNu1ayYOBQkOnOlxFKi6CVFNnPhxoy0SFwINaZOQ+LWdfKdEQwoT1Ya0V/rIPtiPH8nO9u4+ra9dOq0mncZ6qV2vsbPVZq/r8qW+xlbrE536Gm6jRqdRo725SnP9A82NFx/5vLGC67o4vu+TJAnB9Jy78x7eZZ+Hqz7exTeSyQ/UZEB6c4wan5DenCCTAXo6xNxWfUePj+D5HicIAsIo5HA4ort/QO9oRP/smp9XU8LEECaWxe81iDWpYenkgHPvecx8nyiK8LxHnp4CFvMFcRQjIohSiJIK9SaVKqy1OEW7OI7Q2hBGz8z8OYswJk0FpTRKyV/oUvFYZjMcrQURhTEGEY1oXe6NsVibLVVcNoXinLEkYlDa4pR1lSLPc947JstJTQZWOL2dM7oLcYp2hSLwvapjs7z0KrD8qX9oWj37Z/h/CaQS/Ascre+BmFbPagAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Connection Options",
          "title": "Connection Options",
          "src": "/static/0465e85e7c4971a1bb73bffa713df74f/e5715/pingfederate-connection-options.png",
          "srcSet": ["/static/0465e85e7c4971a1bb73bffa713df74f/8514f/pingfederate-connection-options.png 192w", "/static/0465e85e7c4971a1bb73bffa713df74f/804b2/pingfederate-connection-options.png 384w", "/static/0465e85e7c4971a1bb73bffa713df74f/e5715/pingfederate-connection-options.png 768w", "/static/0465e85e7c4971a1bb73bffa713df74f/5a190/pingfederate-connection-options.png 800w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`Click the `}<strong parentName="p">{`Next`}</strong>{` button, enter your specific `}<em parentName="p">{`Domain`}</em>{` authority in the `}<inlineCode parentName="p">{`ISSUER`}</inlineCode>{` field and click the `}<strong parentName="p">{`Load Metadata`}</strong>{` button`}</p>
    <p>{`Give the connection a recognizable name, copy-paste the `}<em parentName="p">{`Client ID`}</em>{` and `}<em parentName="p">{`Client secret`}</em>{` values from your Criipto Verify application`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "42.708333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABrUlEQVQoz3XPbU/TUBjG8X5qnaIjIbzAGEOMfghMkAEbe2DdmKIv4BVL3Nj4Asq2dm1P23NOn/7mtHMhMTT55e5JTq9et/Xu8DM7ewfU6vvUdnZ5+YwXr95uvNnO2us6tZ06u3v7HH78xMH7D1h/FkseFyukWKMfhuh5n+RhgJ7b5bu676GmHZJJk2TaIp22SCZmXhCNGzg3R7i3X3A2LD8QCCFYOS4nZy2OT885+trg5LzFceOM/ugHd7/m2KNrun2bVqfH5WBIs93lfjajfPIEiqxkOY6L4fk+3eF3LvpXtC6HtO0RHXvE9c0dt+MZvaufNHuDUtv+xmm7z3gyQycpUSyJpSpZ67XHYrFEKUWSZuR5QVFAlhdEOkHEkjCWZEVBXvVBa4W5ZML8IESEEUJULCFC/CBAKc3aC3A9H88PEGFMEMVlWKw08h+piKK4bKN0UobqJNmyTDND6yrQMGEm6LezZuUHBLGs1opVZbPe9vyEJaXE0DopPzIzy/Ly74+ux9KrAtM020ifyP5jmRWUlCit8XxRNgyjmCCMcAOBH8X4YUQgKqFpHz3vL6m5hnYOKiSJAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "General Info",
          "title": "General Info",
          "src": "/static/27ba0f27c946d88b8ecf2e134f32a65b/e5715/pingfederate-connection-general.png",
          "srcSet": ["/static/27ba0f27c946d88b8ecf2e134f32a65b/8514f/pingfederate-connection-general.png 192w", "/static/27ba0f27c946d88b8ecf2e134f32a65b/804b2/pingfederate-connection-general.png 384w", "/static/27ba0f27c946d88b8ecf2e134f32a65b/e5715/pingfederate-connection-general.png 768w", "/static/27ba0f27c946d88b8ecf2e134f32a65b/5a190/pingfederate-connection-general.png 800w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`Click the `}<strong parentName="p">{`Next`}</strong>{` button and then click the `}<strong parentName="p">{`Configure Browser SSO`}</strong>{` button`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "31.770833333333332%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABHElEQVQY04WOy0oDQRBF5/+/QMRFfBCjYkCIiwSNgi7ci48JbtyYmSTTPY/ueXb3kZmMigvxwuFW3aKK8taJYhmEaJWRLG6JX65I/Dmy82vytxu0P0X7M/LFFvU67bPp90w9T6iCJ7xQJAgRIeOYwfGYncGIvaMzdg9O2Ds8ZTi+ZP7wyMXsjv3RmOH5hLysaWUdOH5o5YVrQRCGJGnKxzJgtd6wiQRSxgghiSKJlAlCxl0eCUmmFFprlGpRvWsaY/BkqkjThKIoujBOUrQuMMZ22N6/6pamMR2mpe+dsd2fXpaXVFVBXdfUTUNZlhhj+E/GOWpjKasKnMFfae7fU7xUF1Rle7D6teCc+xOcwzqHsW77nbXIvCbIKj4Bts3Exvug7hcAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Browser SSO",
          "title": "Browser SSO",
          "src": "/static/45f5008c26083ff877bb431f6def860c/e5715/pingfederate-connection-browser-sso.png",
          "srcSet": ["/static/45f5008c26083ff877bb431f6def860c/8514f/pingfederate-connection-browser-sso.png 192w", "/static/45f5008c26083ff877bb431f6def860c/804b2/pingfederate-connection-browser-sso.png 384w", "/static/45f5008c26083ff877bb431f6def860c/e5715/pingfederate-connection-browser-sso.png 768w", "/static/45f5008c26083ff877bb431f6def860c/5a190/pingfederate-connection-browser-sso.png 800w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`Choose `}<inlineCode parentName="p">{`NO MAPPING`}</inlineCode>{` for the `}<inlineCode parentName="p">{`Identity Mapping`}</inlineCode></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "39.0625%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABgElEQVQoz5WO7WvUQBCH8/eLop+sIFSoUEo9aKENXjkPhKtSCpajSrForVI8etdsks1usm/JPbI5r4J+cuBhhmGe4ZeM3x6THo1J05TXOxsMd55ytPuMN4PnnAy3Ge1ucPjyyT0HW5HH7G0+ZP/Fmkfsbz4gHWyRyMZSVRJnND8uTvkynXBxOuL8/ZCzySG3lyf420+Yn+c9dvYR5BWoa1DffnMN8iuEnGQhCu6yOxZZzuTDJaN3UwbpmFcHI7b3hny+usG1oExA25badf8Q99p2+BaSrCiZz+dorcmFYD5fIIRAZAJZSspSkmUCIfK+5yL/M+cFZVlSFEU/W2tJVG2oa433Hu8DWV4i8hJZKaRUVEojqxVVREVqlKoxxmGdxzmPsQ7nA4luLN5brHP9MorxOIrrXteGpjHYKDl//6RtW9q2I4QWlh2xEt0YnDP9Q+cCxlisXQlrOQp/431LbT2NsQTn+J5ppjO1SuisIQTP/9QSCN2yTxnT3ZSGs5nmF9IMUp9LLeE0AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Identity Mapping",
          "title": "Identity Mapping",
          "src": "/static/46fa41e592807371d0a337ffe9714937/e5715/pingfederate-connection-identity-mapping.png",
          "srcSet": ["/static/46fa41e592807371d0a337ffe9714937/8514f/pingfederate-connection-identity-mapping.png 192w", "/static/46fa41e592807371d0a337ffe9714937/804b2/pingfederate-connection-identity-mapping.png 384w", "/static/46fa41e592807371d0a337ffe9714937/e5715/pingfederate-connection-identity-mapping.png 768w", "/static/46fa41e592807371d0a337ffe9714937/5a190/pingfederate-connection-identity-mapping.png 800w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`Click the `}<strong parentName="p">{`Next`}</strong>{` button and add the claim types that you want to consume in the `}<inlineCode parentName="p">{`Attribute Contract`}</inlineCode></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "53.645833333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABaklEQVQoz62Sa2tTQRCGz++vN7A2aqHWa9R4/xIVlFJQNB9K0RSjCGIrlmpOLpuzOzt7zsl5JJvjrQo14IeH2XfYeWeH2eTOgzbN1j2azevcutigtd7gxtoyNy+cifrK2eNcWlnicuPYnJWlqG+vL9NaO8218ydprp6K8eq5EyTGeZwT7Dhl51mbt50n7HZf8H5rg53nbezHLfLPr/F728jeNsV+F9J3VF/ewNce9Hs/Y79H0h+OMMbwaf+Au482eLjZodP9wNOXr7j/eBMjBXkFBVAC05qq5rBO0pEhHQxwzmHMBC+essgpi4KqqmLeWov3HlVFvcbzYUQkkmRulhCsc6SDIWaSoZrjNURCyCN6BKPxhLGZkGTiCSHgROinQ6yT+SUNCyFeY93cUDUuZtZhUaPfDDX8YihSjxuOHO8P/vZC8Z4sc//RUDyZdT+WoXX8V2b1/vvI8TvMFqMBq0pRlkyn04Uo65pvFjU9OXnd0fIAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Attribute Contract",
          "title": "Attribute Contract",
          "src": "/static/cc41810ba0f132d2278b61f821930dc7/e5715/pingfederate-connection-attribute-contract.png",
          "srcSet": ["/static/cc41810ba0f132d2278b61f821930dc7/8514f/pingfederate-connection-attribute-contract.png 192w", "/static/cc41810ba0f132d2278b61f821930dc7/804b2/pingfederate-connection-attribute-contract.png 384w", "/static/cc41810ba0f132d2278b61f821930dc7/e5715/pingfederate-connection-attribute-contract.png 768w", "/static/cc41810ba0f132d2278b61f821930dc7/5a190/pingfederate-connection-attribute-contract.png 800w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`You can find the available claim types `}<a parentName="p" {...{
        "href": "/getting-started/token-contents"
      }}>{`here`}</a>{`.`}</p>
    <p>{`Click the `}<strong parentName="p">{`Save`}</strong>{` button and the callback URL you need to finalize the Criipto Verify application configuration is displayed - in PingFederate terms, though, the callback URL is called a `}<inlineCode parentName="p">{`Redirect URI`}</inlineCode>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "34.375%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABbUlEQVQoz53Qb2vTUBTH8bxlW3F/dEgZqCgOBF+COgvtumW1s2nWBruBY44OQrpuOCu6MlvXNDfZzY2a5StJS5lPPfDh/M7hPDrak7WXFB4/5+5ygfzSQ/ILD8jN5BdX5vnOvWVymfvkFqbS29TSSoFnay9YffQUbTj2+H4xYDSeEF6eEjo1VM9E9RrIroE6Mfh1ahI571D2NqGtE3Q2uD6uII5K/Pz49h/alS/x/YAoirCdLsVSmfKmTqmyRUWvUixtUKs3qO7U2TFMdq02HbvHp2OH8/43SGKIf8PNlDa8chmORvi+z+cvX6mZFg1rH6PVpmHtYbT2slxvpj5g7R9yZJ9x0Dmhf/GDtJJbtJHr4bouY9flfyqOY25mkiRB865DgiBASokQPoPBJTJUqChCqSh7h+eJzGTiTaWzEPN9moUQSBmi+VJl/4vjPzhnfYrVFuX3bV5tmrze2uWN3mRdb877+vbM7TyT3vwFnYjfNedAGjsAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Callback URL",
          "title": "Callback URL",
          "src": "/static/53ac6d371fe9cc3055f0ecb7bf86081f/e5715/pingfederate-connection-callback-url.png",
          "srcSet": ["/static/53ac6d371fe9cc3055f0ecb7bf86081f/8514f/pingfederate-connection-callback-url.png 192w", "/static/53ac6d371fe9cc3055f0ecb7bf86081f/804b2/pingfederate-connection-callback-url.png 384w", "/static/53ac6d371fe9cc3055f0ecb7bf86081f/e5715/pingfederate-connection-callback-url.png 768w", "/static/53ac6d371fe9cc3055f0ecb7bf86081f/5a190/pingfederate-connection-callback-url.png 800w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2>{`Complete the application configuration in Criipto Verify`}</h2>
    <p>{`Expand the details of the application configuration if you haven't already done so in the first step.`}</p>
    <p>{`Replace the temporary callback URL (from step 1, `}<inlineCode parentName="p">{`https://example.com`}</inlineCode>{` in this article) in your Criipto Verify application with the actual value now available from your PingFederate authentication source configuration (the value of the `}<inlineCode parentName="p">{`Redirect URI`}</inlineCode>{` above)`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "68.22916666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABTUlEQVQ4y63T226jMBSFYb//281IQGkgHE0ygGliwMEU+CujqOrcjEQ0lj4tcVj7wpZF4fvknkfp+5RBgEoS7nnO7SDXcV2R/v5F4XlI36P0PNT5jHY/ZNkhrlNHEaJOEvrrH0zT8GjbPce6Psz1mnOCmK3FDAMPY9jWlW1ZWF+wbSuqKBDVW0gVhlyjiCp8o0tTeinRZXmI69RxjLi4Ye/OO00U7Zurn5t8hC4K6ihGVH7ANQyRfoCKY3SWcUvTQwdye+Z+KN3lSne50ErJoBT2fmd6gdWaNk0R1hisGXH5OU0s1r5knWdUniOqIKCNTjSnE7ckedk9SahPEaIpCpQsUVIyKsX08XHYo+v2bNxNGY2hH3p0r9F9z2Qtj2na07HzjJ3t9/NP7ts0WT6XBbdUUSLWbeMv68ryw/q0/OO9630PnIeB/2IcadOML99hI9P451l8AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Finalize Application",
          "title": "Finalize Application",
          "src": "/static/7c9d3081854af40a90aee12016155417/0a47e/pingfederate-finalize-application-config.png",
          "srcSet": ["/static/7c9d3081854af40a90aee12016155417/8514f/pingfederate-finalize-application-config.png 192w", "/static/7c9d3081854af40a90aee12016155417/804b2/pingfederate-finalize-application-config.png 384w", "/static/7c9d3081854af40a90aee12016155417/0a47e/pingfederate-finalize-application-config.png 600w"],
          "sizes": "(max-width: 600px) 100vw, 600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`and click the `}<strong parentName="p">{`Save`}</strong>{` button.`}</p>
    <Highlight icon="file-lines" mdxType="Highlight">
      <p>{`  If you plan on using single-signon, you must also register your PingFederate `}<inlineCode parentName="p">{`post_logout_redirect_url`}</inlineCode>{` here so you can run single-logouts.`}</p>
    </Highlight>
    <h2>{`Integrate your own application with PingFederate`}</h2>
    <p>{`How to integrate your application with PingFederate depends on the technology you are working with. Refer to the `}<a parentName="p" {...{
        "href": "https://developer.pingidentity.com/en/cloud-software/pingfederate.html"
      }}>{`PingFederate developer documentation`}</a>{` for more details.`}</p>
    <p>{`If you want to use pass-through of `}<inlineCode parentName="p">{`login_hint`}</inlineCode>{` values sent from your own application to Criipto Verify via PingFederate, you must enable it via a `}<inlineCode parentName="p">{`Policy`}</inlineCode>{` in your `}<inlineCode parentName="p">{`IDP AUTHENTICATION POLICIES`}</inlineCode>{`.`}</p>
    <p>{`If you haven't already done so, create a `}<inlineCode parentName="p">{`Policy Contract`}</inlineCode>{` with the attributes you wish to consume`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "63.020833333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABlklEQVQ4y62T7UsbQRCH9083NnexWisELSiUQhHpFxFpbT8oKIpUtESpifjSKGhrUfHe9vZl9u7yk92cqQHBNnXhYe6W2ZlnGZZNzrzD6MQURsbrqPk+fK+Kmu/1cP9eFSPeMGrVCvwXFdTuqVbgDQ9h4tVLzLypo/56DCxOOFIh0MkzFOfbKE7XkbXXQSerMD/WUJxtQB0uI2osQDaXoFqfoVpLfdh9sf/JRRYlHEIIJJxjbv4jpt9/wOLKJpY3dzH/ZQ2Tb2exs9dE0QHyDp5c7CaMEQShs2x8b+LrTgOtozaO2uc4OD7F1rddXPy6BBG5prZ5mqZIU+G+OU9hjEFRFMjzHCyKE8RxUh7oJhrqJljsMlkOKRWU0o+jqQcLoxi3QQBNhJ+Xv3F1fYMo5tDauGRZHtBkoG10mBJ6ELswIRWklM7wNggd1oZsgQFg1kBrVRaMYKdO1N/1X2BSKWiL1qWdHsjsgWG3oDV0A0r4wHb9hkS9YvQchrZgGHMkQoIL9Wey/3NlnWWQZBxF5y+exSMv5Q4a0s5yGofEXQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Policy Contract",
          "title": "Policy Contract",
          "src": "/static/0505e1c69015d426c9b8d4ddc4db8811/e5715/pingfederate-policy-contract.png",
          "srcSet": ["/static/0505e1c69015d426c9b8d4ddc4db8811/8514f/pingfederate-policy-contract.png 192w", "/static/0505e1c69015d426c9b8d4ddc4db8811/804b2/pingfederate-policy-contract.png 384w", "/static/0505e1c69015d426c9b8d4ddc4db8811/e5715/pingfederate-policy-contract.png 768w", "/static/0505e1c69015d426c9b8d4ddc4db8811/5a190/pingfederate-policy-contract.png 800w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`available claim types can be found `}<a parentName="li" {...{
          "href": "/verify/e-ids"
        }}>{`here`}</a></li>
    </ul>
    <p>{`Then create a `}<inlineCode parentName="p">{`Policy`}</inlineCode>{` for your `}<inlineCode parentName="p">{`IdP Connection`}</inlineCode>{` to Criipto Verify and set the `}<inlineCode parentName="p">{`Options`}</inlineCode>{` for `}<inlineCode parentName="p">{`Incoming User ID`}</inlineCode>{` to be sourced from `}<inlineCode parentName="p">{`Context`}</inlineCode>{` and use the `}<inlineCode parentName="p">{`Requested User`}</inlineCode>{` value.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "53.125%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABuklEQVQoz5WQW2tTQRSFzz9vqzSplyYUq9QXURowYgv6ID4I/gYb7YMimMSSWNrkXGbOmduZ88lMLq0SH9zwsRczazZrT/L02THdwyO693fp7t2h0w7s0Am6tU2ntbXs2+y3tuju7fDi6IAnnTaP99scPtyNPHqwy8G9uySVUtDU2MuvmMk5zL4vuP5G8+sLfvoZPx3c9MkAe/GJenK2ZLDuwZNkWYYQkllRkkqFUA6pHcp5tGtwQPMXm8pD9Cd5URBSFrKk0hrrHLZ2NI2PxjRNGY7GjH9eMByOFoxuM+bHcMhkMo1vkjTNFgOVJq8U+bLPZEkNnA3Oefn6Dadv39Hrn9Drn3LcP/mD571XvP/wkbquSYzRaGOojKUyhlIblLVo63B1HVPmeU5RFEghog5dCIGUAmsNztroa5qGRCmFMYZ5lpEKiawqtLXxMpRzjqvZnKwQCFlyeXXNLMujlmWF0ppKLb7qZqDWeDlHVgLtarRzMWEwBII2zuGXOmwRdO0bnG/iqivvOmGouZCISqGMiaxM/6pwVzcbBurl47msmBVhbUWpNN4vTN77jayG3GadMB5sSPC//Ab/iDVFTIcBoQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Policy",
          "title": "Policy",
          "src": "/static/6d0128167ce9a858c20d9fd3ad6c7b8a/e5715/pingfederate-relay-login_hint.png",
          "srcSet": ["/static/6d0128167ce9a858c20d9fd3ad6c7b8a/8514f/pingfederate-relay-login_hint.png 192w", "/static/6d0128167ce9a858c20d9fd3ad6c7b8a/804b2/pingfederate-relay-login_hint.png 384w", "/static/6d0128167ce9a858c20d9fd3ad6c7b8a/e5715/pingfederate-relay-login_hint.png 768w", "/static/6d0128167ce9a858c20d9fd3ad6c7b8a/5a190/pingfederate-relay-login_hint.png 800w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <Highlight icon="file-lines" mdxType="Highlight">
      <p>{`  You can read more about which per-authorize-request parameters you can use to control the runtime behavior of Criipto Verify `}<a parentName="p" {...{
          "href": "/verify/guides/prefilled-fields"
        }}>{`here (prefilled fields)`}</a>{` and `}<a parentName="p" {...{
          "href": "/how-to/choose-eid-method#login-hint-embedded"
        }}>{`here (acr_values)`}</a>{`.`}</p>
      <p>{`  Leveraging these features makes you authentication source setup in PingFederate as simple as possible - you just need to register Criipto Verify once, and reuse it for all the eID methods you need to consume.`}</p>
    </Highlight>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      